@import '~@angular/material/_theming';
@import 'theme';

$primary-palette: mat-palette($c-primary, 500);
$accent-palette: mat-palette($c-accent, 500);
$warn-palette: mat-palette($mat-red);
$white-palette: mat-palette($mat-grey, 50);

$primary: mat-color($primary-palette);
$accent: mat-color($accent-palette);
$warn: mat-color($warn-palette);
$gray: rgba(0, 0, 0, 0.12);


.accent-color {
    color: $accent; }

.warn-color {
    color: $warn; }

$colorWhite: mat-color($white-palette);
$colorBlack: #141414;
$colorGrey: #5b5f64;
$colorGreyLight: #f2f4f5;

// Colors global optimization when starting theme
$colorMenu: $colorGrey;
$colorActivies: $primary;
$colorPackagesBg: $primary;
$colorPackagesCardHover: $accent;
$colorFooterButton: $accent;
$colorFooterBg: $primary;


body {
    background: $colorWhite; }
